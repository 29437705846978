<template>
  <div class="min-h-screen flex flex-col">
    <!-- Decorative background elements -->
    <div class="fixed inset-0 pointer-events-none -z-10 overflow-hidden">
      <div class="absolute top-0 right-0 w-[800px] h-[800px] bg-gradient-radial from-primary-100/30 to-transparent rounded-full translate-x-1/3 -translate-y-1/2"></div>
      <div class="absolute bottom-0 left-0 w-[600px] h-[600px] bg-gradient-radial from-primary-100/30 to-transparent rounded-full -translate-x-1/3 translate-y-1/2"></div>
    </div>

    <TheHeader />

    <main class="flex-1">
      <!-- Hero Section -->
      <section class="relative overflow-hidden section-spacing">
        <div class="container mx-auto px-4">
          <div class="grid lg:grid-cols-2 gap-12 lg:gap-16 items-center">
            <div class="space-y-6 lg:space-y-8 max-w-2xl">
              <div class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-primary-50 text-primary-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 001.415-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
                </svg>
                Tehosta liiketoimintaasi
              </div>

              <h1 class="font-display text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-neutral-900">
                <span class="block">Hankeapuri AI</span>
                <span class="block mt-2 gradient-text bg-gradient-to-r from-primary-600 to-primary-500">
                  Älykäs apuri liiketoiminnan kehittämiseen
                </span>
              </h1>

              <p class="text-lg md:text-xl text-neutral-600">
                Helpota hankerahoituksen hakemista, liiketoimintasuunnitelmien laatimista ja raporttien luomista. 
                Syötä perustiedot, anna tekoälyn hoitaa loput.
              </p>

              <div class="flex flex-col sm:flex-row gap-4">
                <a 
                  :href="isLoggedIn ? '/hankkeet' : '/register'" 
                  class="btn btn-primary btn-lg group relative overflow-hidden"
                >
                  <span class="relative z-10">{{ isLoggedIn ? 'Siirry Palveluun' : 'Aloita nyt' }}</span>
                  <span class="absolute inset-0 bg-gradient-to-r from-primary-600 to-primary-500 group-hover:opacity-0 transition-opacity duration-300"></span>
                  <span class="absolute inset-0 bg-gradient-to-r from-primary-700 to-primary-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                </a>
                <a 
                  href="#demo" 
                  class="btn btn-primary btn-lg flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                  </svg>
                  Katso demo
                </a>
              </div>
            </div>

            <div class="relative">
              <!-- Image decorative elements -->
              <div class="absolute -inset-4 bg-gradient-to-br from-primary-500/20 via-transparent to-primary-400/20 blur-2xl rounded-2xl opacity-70"></div>
              
              <!-- Animated floating badges -->
              
              <div class="absolute -bottom-5 -right-6 animate-float delay-700">
                <div class="flex items-center space-x-2 bg-white px-3 py-2 rounded-lg shadow-soft-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm font-medium text-neutral-800">Nopea & Tehokas</span>
                </div>
              </div>

              <!-- Main hero image -->
              <div class="relative z-10 rounded-2xl overflow-hidden shadow-2xl">
                <img 
                  src="../assets/kuva.png" 
                  alt="AI illustration" 
                  class="w-full h-auto object-cover transition-transform duration-700 hover:scale-105"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Features Section -->
      <section class="section-spacing bg-neutral-50">
        <div class="container mx-auto px-4">
          <div class="text-center max-w-3xl mx-auto mb-16">
            <div class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-primary-50 text-primary-700 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 001.414 0l.707-.707a1 1 0 001.414 0l.707.707a1 1 0 001.414 0l.707-.707a1 1 0 000-1.414l-.707-.707a1 1 0 00-1.414 0l-.707.707A1 1 0 006.586 7H7a1 1 0 000-2h5a1 1 0 00.707-1.707l-.707-.707a1 1 0 00-1.414 0l-.707.707a1 1 0 00-1.414 0l-.707-.707a1 1 0 00-1.414 0l-.707.707a1 1 0 00-1.414 0l-.707-.707a1 1 0 00-1.414 0l-.707.707a1 1 0 00-1.414 0l-.707-.707a1 1 0 000-1.414l.707-.707a1 1 0 001.414 0l.707.707a1 1 0 001.414 0l.707-.707a1 1 0 001.414 0l.707.707a1 1 0 001.414 0l.707-.707a1 1 0 000-1.414l-.707-.707z" clip-rule="evenodd" />
              </svg>
              Ominaisuudet
            </div>
            <h2 class="font-display text-3xl md:text-4xl font-bold text-neutral-900 mb-6">
              Monipuolinen työkalu liiketoiminnan kehittämiseen
            </h2>
            <p class="text-lg text-neutral-600">
              Hankeapuri AI nopeuttaa ja tehostaa kaikkia liiketoiminnan kehittämiseen liittyviä dokumentteja,
              aina suunnittelusta raportointiin.
            </p>
          </div>

          <div class="grid md:grid-cols-3 gap-6 lg:gap-8">
            <!-- Feature 1 -->
            <div class="card card-hover transition-all p-6 lg:p-8">
              <div class="w-12 h-12 flex-center rounded-lg bg-primary-100 text-primary-600 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Rahoitushakemukset</h3>
              <p class="text-neutral-600 mb-4">
                Luo ammattimaisia ja vakuuttavia rahoitushakemuksia tekoälyn avulla. Optimoidut hakemukset parantavat mahdollisuuksiasi rahoituksen saamiseen.
              </p>
              <a href="/palvelut" class="inline-flex items-center text-primary-600 font-medium hover:text-primary-700">
                Lue lisää
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>

            <!-- Feature 2 -->
            <div class="card card-hover transition-all p-6 lg:p-8">
              <div class="w-12 h-12 flex-center rounded-lg bg-primary-100 text-primary-600 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Liiketoimintasuunnitelmat</h3>
              <p class="text-neutral-600 mb-4">
                Laadi kattavia liiketoimintasuunnitelmia tekoälyn ohjauksella. Räätälöidyt suunnitelmat varmistavat, että kaikki oleelliset näkökulmat tulevat huomioiduiksi.
              </p>
              <a href="/palvelut" class="inline-flex items-center text-primary-600 font-medium hover:text-primary-700">
                Lue lisää
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>

            <!-- Feature 3 -->
            <div class="card card-hover transition-all p-6 lg:p-8">
              <div class="w-12 h-12 flex-center rounded-lg bg-primary-100 text-primary-600 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Hankeraportit</h3>
              <p class="text-neutral-600 mb-4">
                Tuota ammattimaisia hankeraportteja ja -dokumentteja nopeasti. Säästä aikaa automaattisilla raportointityökaluilla.
              </p>
              <a href="/palvelut" class="inline-flex items-center text-primary-600 font-medium hover:text-primary-700">
                Lue lisää
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <!-- AI Power Section -->
      <section class="section-spacing overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="grid lg:grid-cols-2 gap-12 lg:gap-16 items-center">
            <!-- Image with decorative elements -->
            <div class="relative">
              <div class="absolute -inset-4 bg-gradient-to-tr from-primary-500/20 via-transparent to-primary-400/20 blur-2xl rounded-full opacity-60"></div>
              
              <!-- Animated floating badges -->
              
              <div class="absolute -bottom-5 -right-6 animate-float delay-700">
                <div class="flex items-center space-x-2 bg-white px-3 py-2 rounded-lg shadow-soft-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm font-medium text-neutral-800">Nopea & Tehokas</span>
                </div>
              </div>

              <!-- Main hero image -->
              <div class="relative z-10 rounded-2xl overflow-hidden shadow-2xl">
                <img 
                  src="kuva2.png" 
                  alt="Tekoälyn voimaa" 
                  class="w-full h-auto object-cover transition-all duration-700 hover:scale-105 hover:rotate-1"
                />
              </div>
              
              <!-- Decorative elements -->
            </div>
            
            <!-- Content -->
            <div class="space-y-6 lg:space-y-8">
              <div class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-primary-50 text-primary-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M11.3 1.046A9.001 9.001 0 1020.945 13H11V3.055z" />
                </svg>
                Tekoälyn voima
              </div>
              
              <h2 class="font-display text-3xl md:text-4xl font-bold text-neutral-900">
                Tekoälyn voimaa liiketoiminnan tueksi
              </h2>
              
              <p class="text-lg text-neutral-600">
                Hankeapuri AI hyödyntää edistynyttä tekoälyä analysoidakseen tietoja ja luodakseen räätälöityjä dokumentteja. Älykäs algoritmi optimoi sisällön vastaamaan tarpeisiisi ja maksimoi onnistumismahdollisuutesi.
              </p>
              
              <!-- Feature list -->
              <div class="space-y-4 my-8">
                <div class="flex items-start">
                  <div class="flex-shrink-0 mt-1">
                    <div class="w-5 h-5 rounded-full bg-primary-100 flex-center text-primary-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <p class="ml-3 text-neutral-700">Tekoäly analysoi dataa ja tuottaa räätälöityjä ehdotuksia</p>
                </div>
                
                <div class="flex items-start">
                  <div class="flex-shrink-0 mt-1">
                    <div class="w-5 h-5 rounded-full bg-primary-100 flex-center text-primary-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <p class="ml-3 text-neutral-700">Oppii jatkuvasti käytöstä ja parantaa tuloksia ajan myötä</p>
                </div>
                
                <div class="flex items-start">
                  <div class="flex-shrink-0 mt-1">
                    <div class="w-5 h-5 rounded-full bg-primary-100 flex-center text-primary-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <p class="ml-3 text-neutral-700">Automaattinen dokumenttien optimointi hakuvaatimusten mukaan</p>
                </div>
              </div>
              
              <a href="/register" class="btn btn-accent btn-lg inline-flex items-center">
                Aloita käyttö
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <!-- Demo Video Section -->
      <section id="demo" class="section-spacing bg-neutral-50 overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="text-center max-w-3xl mx-auto mb-16">
            <div class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-primary-50 text-primary-700 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
              </svg>
              Demo
            </div>
            <h2 class="font-display text-3xl md:text-4xl font-bold text-neutral-900 mb-6">
              Katso Hankeapuri AI toiminnassa
            </h2>
            <p class="text-lg text-neutral-600">
              Näe miten Hankeapuri AI toimii käytännössä ja miten se voi auttaa sinua hankerahoituksessa.
            </p>
          </div>
          
          <div class="max-w-4xl mx-auto relative">
            <!-- Video decorative elements -->
            <div class="absolute -inset-px rounded-3xl bg-gradient-to-tr from-primary-500 via-primary-400 to-primary-500 opacity-70 blur-xl"></div>
            
            <!-- Decorative patterns -->
            <div class="absolute -bottom-10 -right-16 opacity-20 hidden lg:block">
              <svg width="130" height="130" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
                <path d="M65 0C101.6 0 130 29.4 130 65C130 101.6 101.6 130 65 130C29.4 130 0 101.6 0 65C0 29.4 29.4 0 65 0ZM65 10C35.7 10 10 35.7 10 65C10 95.3 34.7 120 65 120C94.3 120 120 95.3 120 65C120 35.7 94.3 10 65 10Z" fill="url(#grad-circle)"/>
                <defs>
                  <linearGradient id="grad-circle" x1="0" y1="0" x2="130" y2="130" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3B82F6"/>
                    <stop offset="1" stop-color="#3B82F6"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            
            <div class="relative z-10 rounded-3xl overflow-hidden shadow-2xl border-8 border-white">
              <div class="aspect-w-16 aspect-h-9 bg-neutral-100">
                <iframe 
                  class="w-full h-full"
                  src="https://www.youtube.com/embed/N4sovTlsME4" 
                  title="Hankeapuri AI Demo"
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Benefits Section -->
      <section class="section-spacing">
        <div class="container mx-auto px-4">
          <div class="text-center max-w-3xl mx-auto mb-16">
            <div class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-primary-50 text-primary-700 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              Hyödyt
            </div>
            <h2 class="font-display text-3xl md:text-4xl font-bold text-neutral-900 mb-6">
              Keskeiset Hyödyt
            </h2>
            <p class="text-lg text-neutral-600">
              Hankeapuri AI tehostaa liiketoiminnan kehittämistä monipuolisilla työkaluilla ja älykkäällä avustuksella.
            </p>
          </div>
          
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
            <!-- Benefit 1 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Ajansäästö</h3>
              <p class="text-neutral-600">
                Automatisoidut hakemuspohjat ja dokumentit valmistuvat nopeasti ja vaivattomasti.
              </p>
            </div>
            
            <!-- Benefit 2 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Intuitiivinen</h3>
              <p class="text-neutral-600">
                Käyttäjäystävällinen alusta sopii niin aloittelijoille kuin ammattilaisille.
              </p>
            </div>
            
            <!-- Benefit 3 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Tekoälyn tuki</h3>
              <p class="text-neutral-600">
                Tekoäly tarjoaa reaaliaikaisia ratkaisuja ja ehdotuksia sisällön optimointiin.
              </p>
            </div>
            
            <!-- Benefit 4 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Ajan hermolla</h3>
              <p class="text-neutral-600">
                Pidä hankedokumentaatiosi aina ajan tasalla markkinoiden viimeisimpien vaatimusten mukaisesti.
              </p>
            </div>
            
            <!-- Benefit 5 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Tehostaminen</h3>
              <p class="text-neutral-600">
                Optimoi rahoitushakemuksen jokainen vaihe parantaen mahdollisuuksiasi menestyksekkääseen rahoituksen saantiin.
              </p>
            </div>
            
            <!-- Benefit 6 -->
            <div class="card card-hover p-6 lg:p-8 text-center">
              <div class="w-14 h-14 flex-center rounded-full bg-primary-100 text-primary-600 mx-auto mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                </svg>
              </div>
              <h3 class="text-xl font-bold text-neutral-900 mb-3">Omat Hanke Pohjat</h3>
              <p class="text-neutral-600">
                Luo helposti omia hakemuspohjia, jotka auttavat missä tahansa hakemuksessa.
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Call to Action -->
      <section class="relative overflow-hidden bg-primary-900 text-white py-16 md:py-24 lg:py-32">
        <!-- Background decorations -->
        <div class="absolute inset-0 opacity-10">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid-pattern" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="white" stroke-width="1" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid-pattern)" />
          </svg>
        </div>
        
        <!-- Glowing orb -->
        <div class="absolute top-0 right-0 -mt-24 -mr-24 w-96 h-96 bg-primary-600 rounded-full filter blur-3xl opacity-20"></div>
        <div class="absolute bottom-0 left-0 -mb-24 -ml-24 w-96 h-96 bg-primary-500 rounded-full filter blur-3xl opacity-20"></div>
        
        <div class="container mx-auto px-4 relative z-10">
          <div class="max-w-3xl mx-auto text-center">
            <h2 class="font-display text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
              Valmis tehostamaan liiketoimintaasi?
            </h2>
            <p class="text-lg md:text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              Kokeile nyt ilmaiseksi Hankeapuri AI:ta ja näe, miten se voi mullistaa liiketoimintasi kehittämisen.
            </p>
            <a href="/register" class="btn inline-flex items-center px-8 py-4 rounded-xl text-base md:text-lg font-semibold bg-white text-primary-700 hover:bg-white/90 transition-all shadow-xl hover:shadow-2xl hover:-translate-y-1">
              Kokeile nyt ilmaiseksi
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
      </section>
    </main>

    <!-- Footer -->
    <footer class="bg-neutral-900 text-white pt-16 pb-8">
      <div class="container mx-auto px-4">
        <div class="grid md:grid-cols-2 gap-8 pb-8 border-b border-white/10">
          <div>
            <div class="text-2xl font-bold mb-4">Hankeapuri AI</div>
            <p class="text-white/70 max-w-md">
              Älykäs apuri liiketoiminnan kehittämiseen. Tehosta hankerahoituksen hakemista ja varmista liiketoimintasi kasvu Hankeapuri AI:n avulla.
            </p>
          </div>
          
          <div class="md:text-right">
            <h3 class="text-lg font-semibold mb-4">Linkit</h3>
            <nav class="flex flex-col md:items-end space-y-3">
              <a href="/palvelut" class="text-white/70 hover:text-white transition-colors">Palvelut</a>
              <a href="/hinnoittelu" class="text-white/70 hover:text-white transition-colors">Hinnoittelu</a>
              <a href="/tuki" class="text-white/70 hover:text-white transition-colors">Tuki</a>
              <a href="/ehdot" class="text-white/70 hover:text-white transition-colors">Käyttöehdot</a>
              <a href="/yksityisyys" class="text-white/70 hover:text-white transition-colors">Yksityisyys</a>
            </nav>
          </div>
        </div>
        
        <div class="pt-8 flex flex-col md:flex-row md:items-center justify-between">
          <p class="text-sm text-white/60">
            {{ new Date().getFullYear() }} Hankeapuri AI - JH Apps (Kaikki oikeudet pidätetään).
          </p>
          
          <div class="flex space-x-4 mt-4 md:mt-0">
            <a href="#" class="text-white/60 hover:text-white transition-colors">
              <span class="sr-only">Twitter</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
              </svg>
            </a>
            
            <a href="#" class="text-white/60 hover:text-white transition-colors">
              <span class="sr-only">LinkedIn</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" clip-rule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'HankeapuriAI',
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.isAuthenticated);
    
    return {
      isLoggedIn
    };
  },
};
</script>

<style>
/* Add the aspect ratio for video embeds */
.aspect-w-16 {
  position: relative;
  padding-bottom: 56.25%;
}

.aspect-w-16 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Add hover transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
</style>