// src/router/index.js

import { createRouter, createWebHistory } from "vue-router";

import HankeApuriComponent from "@/views/hankeapuri.vue";
import LandingPage from "@/views/landingPage.vue";

import SuccessPage from "@/views/SuccessPage.vue";
import CancelPage from "@/views/CancelPage.vue";

import LoginPage from "@/views/LoginPage.vue"; // Add the new route for the pricing page
import SignupPage from "@/views/SignupPage.vue"; // Add the new route for the pricing page
import HankeCreator from "@/views/HankeCreator.vue"; // Add the new route for the pricing page
import RaporttiCreator from "@/views/RaporttiGenerator.vue"; // Add the new route for the pricing page

import projectListView from "@/views/ProjetListView.vue"; // Add the new route for the pricing page

import CreditsPage from "@/views/CreditsPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";

import PrivacyPage from "@/views/PrivacyPolicy.vue";
import TermsPage from "@/views/TermsPage.vue";


import ChatBotView from "@/views/ChatBotView.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/success",
    name: "Success",
    component: SuccessPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: CancelPage,
  },
  {
    path: "/hankeBot",
    name: "ChatBotView",
    component: ChatBotView,
  },
  {
    path: "/ehdot",
    name: "TermsPage",
    component: TermsPage,
  },
 

  {
    path: "/hankkeet",
    name: "projectList",
    component: projectListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/yksityisyys",
    name: "PrivacyPage",
    component: PrivacyPage,
  },

  {
    path: "/hankeapuri/:projectId?",
    name: "HankeApuri",
    component: HankeApuriComponent,
    meta: { requiresAuth: true },
  },

  {
    path: "/login",
    name: "Login",
    component: LoginPage, // Add the new route for the pricing page
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupPage, // Add the new route for the pricing page
  },
  {
    path: "/hankepohjat",
    name: "hankepohjat",
    component: HankeCreator, // Add the new route for the pricing page
    meta: { requiresAuth: true },
  },
  {
    path: "/hankecreator",
    name: "HankeCreator",
    component: HankeCreator, // Add the new route for the pricing page
    meta: { requiresAuth: true },
  },
  {
    path: "/raporttigenerator",
    name: "RaporttiCreator",
    component: RaporttiCreator, // Add the new route for the pricing page
  },
  {
    path: "/osta",
    name: "Credits",
    component: CreditsPage, // Add the new route for the pricing page
    meta: { requiresAuth: true },
  },
  // ... (other routes)
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
