<template>
    <div class="min-h-screen bg-gradient-to-b from-slate-50 to-teal-50 text-slate-800">
        <div class="container mx-auto px-4 py-6 max-w-4xl">
            <!-- Header section with Imatra branding -->
            <div class="text-center mb-8">
                <div class="flex justify-center mb-4">
                    <div class="bg-teal-700 text-white px-6 py-3 rounded-lg shadow-lg inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 mr-3" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="text-2xl font-bold">Imatra Grants</span>
                    </div>
                </div>
                <h1 class="text-3xl sm:text-4xl font-bold text-teal-800 mb-4">Hankeapuri - Imatran seudun
                    rahoitusneuvoja</h1>
                <p class="text-lg text-slate-700 max-w-3xl mx-auto">
                    Tervetuloa keskustelemaan Imatran seudun äkillisen rakennemuutoksen erillismäärärahasta.
                    Asiantuntijamme auttaa sinua selvittämään, soveltuuko yrityksesi tähän rahoitusmuotoon ja opastaa
                    hakuprosessissa.
                </p>
            </div>

            <!-- Grants information box -->
            <div class="bg-white rounded-xl shadow-md overflow-hidden mb-8 border-l-4 border-teal-600">
                <div class="p-5 bg-teal-50">
                    <h2 class="text-xl font-bold text-teal-800 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Ajankohtaista: Yrityksen kehittämisavustus (EAKR) Etelä-Karjalassa
                    </h2>
                    <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p class="text-sm font-semibold text-teal-700">Haun tiedot:</p>
                            <ul class="mt-1 text-sm space-y-1">
                                <li><span class="font-medium">Hakuaika:</span> 13.1.2025 - 23.5.2025</li>
                                <li><span class="font-medium">Rahoitus:</span> 4 000 000 € (josta 2 000 000 € Imatran
                                    seudulle)</li>
                                <li><span class="font-medium">Kohdealue:</span> Etelä-Karjala (Imatra, Parikkala,
                                    Rautjärvi, Ruokolahti)</li>
                                <li><span class="font-medium">Hakutyyppi:</span> Jatkuva hankehaku</li>
                            </ul>
                        </div>
                        <div>
                            <p class="text-sm font-semibold text-teal-700">Soveltuvuus:</p>
                            <ul class="mt-1 text-sm space-y-1">
                                <li>PK-yrityksille, jotka tavoittelevat kasvua tai kansainvälistymistä</li>
                                <li>Yrityksille, jotka panostavat digitalisaatioon tai innovaatioihin</li>
                                <li>Aloittaville tai toimintaansa merkittävästi uudistaville yrityksille</li>
                                <li>Tuki kehittämistoimenpiteisiin (max 50%) ja investointeihin (10-40%)</li>
                            </ul>
                        </div>
                    </div>
                    <p class="mt-3 text-sm text-gray-600">
                        Kysy asiantuntijaltamme lisätietoja tai tarkempia ohjeita hakemiseen. Voimme auttaa sinua
                        selvittämään, soveltuuko yrityksesi tähän rahoitukseen.
                    </p>
                </div>
            </div>

            <!-- Navigation tabs for company info and chat -->
            <div class="mb-6">
                <div class="flex border-b border-teal-200">
                    <button @click="activeTab = 'info'" class="py-3 px-4 font-medium transition-colors duration-200"
                        :class="activeTab === 'info' ? 'text-teal-700 border-b-2 border-teal-600' : 'text-slate-500 hover:text-teal-600'">
                        <span class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm3 1h6v4H7V5zm8 8v2H5v-2h10zM7 10h2v2H7v-2zm4 0h2v2h-2v-2z"
                                    clip-rule="evenodd" />
                            </svg>
                            Yrityksen tiedot
                        </span>
                    </button>
                    <button @click="activeTab = 'chat'" class="py-3 px-4 font-medium transition-colors duration-200"
                        :class="activeTab === 'chat' ? 'text-teal-700 border-b-2 border-teal-600' : 'text-slate-500 hover:text-teal-600'">
                        <span class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                <path
                                    d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                            </svg>
                            Keskustele asiantuntijan kanssa
                        </span>
                    </button>
                </div>
            </div>

            <!-- Content based on active tab -->
            <div class="bg-white rounded-xl shadow-md overflow-hidden">
                <!-- Company Information Tab -->
                <div v-if="activeTab === 'info'" class="p-6 space-y-6">
                    <h3 class="text-xl font-semibold text-teal-700 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm3 1h6v4H7V5zm8 8v2H5v-2h10zM7 10h2v2H7v-2zm4 0h2v2h-2v-2z"
                                clip-rule="evenodd" />
                        </svg>
                        Yrityksen tiedot
                    </h3>

                    <textarea v-model="companyDetails"
                        placeholder="Kerro yrityksestäsi, toimialasta, sijainnista (onko Imatran seudulla?) ja kehitystarpeista..."
                        class="w-full h-40 p-4 bg-white border border-slate-200 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-200 resize-y"></textarea>

                    <div class="bg-teal-50 p-4 rounded-lg border border-teal-200 space-y-4">
                        <h4 class="text-lg font-semibold text-teal-700 flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                <path fill-rule="evenodd"
                                    d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                                    clip-rule="evenodd" />
                            </svg>
                            Lataa yrityksen tiedot
                        </h4>
                        <p class="text-sm text-slate-600">Syötä Y-tunnus hakeaksesi yrityksen tiedot automaattisesti</p>
                        <div class="flex">
                            <input type="text" v-model="yTunnus" placeholder="Syötä Y-tunnus"
                                class="flex-grow py-2 px-4 border border-slate-200 rounded-l-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-200" />
                            <button @click="loadCompanyInfo"
                                class="bg-gradient-to-r from-teal-600 to-teal-700 hover:from-teal-700 hover:to-teal-800 text-white py-2 px-4 rounded-r-lg flex items-center transition duration-300">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                        clip-rule="evenodd" />
                                </svg>
                                Lataa
                            </button>
                        </div>
                    </div>

                    <div class="flex justify-end mt-4">
                        <button @click="activeTab = 'chat'"
                            class="bg-teal-600 hover:bg-teal-700 text-white py-2 px-6 rounded-lg flex items-center transition duration-300">
                            Siirry keskusteluun
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>

                <!-- Chat Tab -->
                <div v-if="activeTab === 'chat'" class="p-6">
                    <h3 class="text-xl font-semibold text-teal-700 mb-4 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                            <path
                                d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                        </svg>
                        Keskustele rahoitusasiantuntijan kanssa
                    </h3>

                    <!-- Error message if present -->
                    <div v-if="error"
                        class="bg-red-50 border-l-4 border-red-500 text-red-700 p-4 rounded mb-4 flex items-start">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 mt-0.5" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                        <span>{{ error }}</span>
                    </div>

                    <!-- Chat container -->
                    <div class="bg-slate-50 border border-slate-200 rounded-xl overflow-hidden">
                        <!-- Chat messages area with markdown support -->
                        <div ref="chatArea" class="h-[650px] overflow-y-auto p-4 space-y-4 chat-area scroll-smooth">
                            <div v-if="chatHistory.length === 0"
                                class="flex flex-col items-center justify-center h-full text-center text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mb-3 text-teal-300" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                </svg>
                                <p>Kysy AI asiantuntijalta neuvoa Imatran seudun rahoitusmahdollisuuksista.<br>Kerro
                                    ensin
                                    hieman yrityksestäsi.</p>
                            </div>

                            <div v-for="(message, index) in chatHistory" :key="index" class="flex"
                                :class="{ 'justify-end': message.sender === 'Käyttäjä', 'justify-start': message.sender === 'Chatbot' }">
                                <div class="max-w-[85%]" :class="{ 'order-2': message.sender === 'Käyttäjä' }">
                                    <div class="font-medium text-sm mb-1"
                                        :class="{ 'text-right text-teal-700': message.sender === 'Käyttäjä', 'text-left text-blue-700': message.sender === 'Chatbot' }">
                                        {{ message.sender === 'Chatbot' ? 'Rahoitusasiantuntija' : 'Sinä' }}
                                    </div>

                                    <!-- Markdown supported message for chatbot replies -->
                                    <div class="p-3 rounded-lg inline-block text-sm shadow-sm" :class="{
                                        'bg-teal-600 text-white rounded-br-none': message.sender === 'Käyttäjä',
                                        'bg-white border border-slate-200 rounded-bl-none prose prose-sm max-w-none prose-headings:mt-2 prose-headings:mb-1': message.sender === 'Chatbot'
                                    }">
                                        <div v-if="message.sender === 'Chatbot'" v-html="renderMarkdown(message.text)">
                                        </div>
                                        <div v-else>{{ message.text }}</div>
                                    </div>

                                    <!-- Optional timestamp -->
                                    <div v-if="message.timestamp" class="text-xs text-slate-400 mt-1"
                                        :class="{ 'text-right': message.sender === 'Käyttäjä', 'text-left': message.sender === 'Chatbot' }">
                                        {{ formatTime(message.timestamp) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Typing indicator -->
                        <div v-if="loading" class="flex items-center p-3 bg-slate-50 border-t border-slate-200">
                            <div class="flex space-x-1 typing-indicator">
                                <div class="w-2 h-2 rounded-full bg-teal-600 animate-bounce dot"></div>
                                <div class="w-2 h-2 rounded-full bg-teal-600 animate-bounce dot"></div>
                                <div class="w-2 h-2 rounded-full bg-teal-600 animate-bounce dot"></div>
                            </div>
                            <span class="ml-2 text-sm text-slate-500">Rahoitusasiantuntija kirjoittaa...</span>
                        </div>

                        <!-- Input area -->
                        <div class="p-3 bg-white border-t border-slate-200">
                            <div class="flex items-center">
                                <input type="text" v-model="message"
                                    placeholder="Kysy Imatran seudun rahoitusmahdollisuuksista..."
                                    class="flex-grow py-3 px-4 bg-slate-50 border border-slate-200 rounded-l-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-200"
                                    @keyup.enter="sendMessage" :disabled="loading" />
                                <button @click="sendMessage"
                                    class="bg-gradient-to-r from-teal-600 to-teal-700 hover:from-teal-700 hover:to-teal-800 text-white py-3 px-4 rounded-r-lg transition duration-300 disabled:opacity-50"
                                    :disabled="loading || !message.trim()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                    </svg>
                                </button>
                            </div>

                            <div class="text-xs text-slate-500 mt-2 text-center">
                                Voit kysyä apua Imatran seudun äkillisen rakennemuutoksen erillismäärärahasta,
                                hakuprosessista, tukiprosenteista tai muista yrityksen kehittämisavustukseen liittyvistä
                                asioista.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bottom info section -->
            <div class="mt-8 bg-white rounded-xl shadow-md overflow-hidden">
                <div class="p-5 bg-teal-800 text-white">
                    <h3 class="text-lg font-semibold flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd" />
                        </svg>
                        Lisätietoa avustuksesta
                    </h3>
                </div>
                <div class="p-5 text-sm">
                    <p class="mb-3">
                        <span class="font-semibold">Yrityksen kehittämisavustus EAKR</span> on tarkoitettu
                        pk-yrityksille, jotka aloittavat toimintaansa, haluavat panostaa merkittävästi uudistumiseen tai
                        pyrkivät kasvamaan. Hämeen ELY-keskuksen kautta on haettavana 4 000 000 euroa, josta 2 000 000
                        euroa on suunnattu erityisesti Imatran seudulle äkillisen rakennemuutoksen tilanteessa.
                    </p>
                    <p class="mb-3">
                        <span class="font-semibold">Tukikohteita:</span> Kehittämistoimenpiteet (max 50% tuki) ja
                        investoinnit (10-40% tuki sijainnista ja yrityksen koosta riippuen).
                    </p>
                    <p class="mb-3">
                        <span class="font-semibold">Kehittämishankkeiden kustannuksia</span> voivat olla mm.
                        palkkakulut, ulkopuolisten palvelujen käyttö, kansainvälistymistä edistäviin messuihin
                        osallistuminen ja tuotekehitys.
                    </p>
                    <p>
                        <span class="font-semibold">Rahoituksen hakeminen:</span> Avustusta haetaan sähköisesti EURA
                        2021 -järjestelmässä. Hakemukset käsitellään saapumisjärjestyksessä ja rahoituskokouksia
                        pidetään kahden viikon välein. Hakemus tulee jättää ennen hankkeen aloittamista.
                    </p>
                </div>
                <div class="p-5 bg-teal-50 border-t border-teal-100">
                    <p class="text-sm">
                        <span class="font-semibold">Yhteystiedot:</span> Valtakunnallinen yritysrahoitusneuvonta
                        palvelee arkisin klo 9–15 numerossa 0295 024 800 tai sähköpostitse
                        pkrahoitusneuvonta@ely-keskus.fi.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, nextTick } from 'vue';
import axios from 'axios';
import { marked } from 'marked'; // Import markdown parser

export default {
    setup() {
        const companyDetails = ref('');
        const yTunnus = ref('');
        const message = ref('');
        const chatHistory = ref([]);
        const loading = ref(false);
        const error = ref(null);
        const chatArea = ref(null);
        const activeTab = ref('info'); // Default to info tab

        // Configure marked for security
        marked.setOptions({
            gfm: true,
            breaks: true,
            sanitize: true,
        });

        // Markdown renderer function
        const renderMarkdown = (text) => {
            if (!text) return '';
            return marked(text);
        };

        // Format timestamp
        const formatTime = (timestamp) => {
            if (!timestamp) return '';
            const date = new Date(timestamp);
            return date.toLocaleTimeString('fi-FI', { hour: '2-digit', minute: '2-digit' });
        };

        // Add initial welcome message with timestamp
        onMounted(() => {
            chatHistory.value.push({
                sender: 'Chatbot',
                text: `# Tervetuloa Hankeapuriin!

Olen Imatran seudun AI rahoitusasiantuntija. Autan sinua selvittämään, onko yrityksesi sopiva hakemaan kehittämisavustusta ja erityisesti Imatran seudun äkillisen rakennemuutoksen erillismäärärahaa.

**Kerro minulle yrityksestäsi esimerkiksi:**
- Toimiala ja sijainti
- Yrityksen koko ja ikä
- Kehittämistarpeet ja kasvutavoitteet`,
                timestamp: new Date()
            });

            if (chatArea.value) {
                chatArea.value.scrollTop = chatArea.value.scrollHeight;
            }
        });

        const loadCompanyInfo = async () => {
            if (!yTunnus.value.trim()) {
                error.value = 'Syötä Y-tunnus ensin';
                setTimeout(() => { error.value = null; }, 3000); // Clear error after 3 seconds
                return;
            }

            try {
                error.value = null;
                loading.value = true;
                const response = await axios.get(`/api/company-info?yTunnus=${yTunnus.value}`);
                companyDetails.value = response.data.companyDetails;

                // Switch to chat tab after successful company info load
                activeTab.value = 'chat';
                loading.value = false;
            } catch (err) {
                console.error('Error loading company info:', err);
                error.value = 'Yritystietojen lataus epäonnistui. Yritä uudelleen.';
                loading.value = false;
            }
        };

        const sendMessage = async () => {
            if (!message.value.trim()) return;

            try {
                loading.value = true;

                // Add user message with timestamp
                chatHistory.value.push({
                    sender: 'Käyttäjä',
                    text: message.value,
                    timestamp: new Date()
                });

                // Save and clear input
                message.value = '';

                // Using the Azure Functions endpoint
                const response = await axios.post('https://jhapps-flutterapps.azurewebsites.net/api/hankeapuriGrants', {
                    userDetails: companyDetails.value,
                    chatHistory: chatHistory.value
                        .filter(msg => msg.text && msg.text.trim() !== '')
                        .map(msg => ({
                            role: msg.sender === 'Käyttäjä' ? 'user' : 'assistant',
                            content: msg.text
                        }))
                });

                // Add chatbot response with timestamp
                const chatbotResponse = response.data.response || response.data;
                chatHistory.value.push({
                    sender: 'Chatbot',
                    text: chatbotResponse,
                    timestamp: new Date()
                });

                // Ensure scroll to bottom after DOM update
                await nextTick();
                scrollToBottom();

                error.value = null;
            } catch (err) {
                console.error('Error sending message:', err);
                error.value = 'Viestin lähetys epäonnistui. Yritä uudelleen.';

                // Remove the last user message if there was an error
                if (chatHistory.value.length > 0 && chatHistory.value[chatHistory.value.length - 1].sender === 'Käyttäjä') {
                    chatHistory.value.pop();
                }
            } finally {
                loading.value = false;
            }
        };

        watch(chatHistory, () => {
            // Use nextTick to ensure DOM is updated before scrolling
            nextTick(() => {
                scrollToBottom();
            });
        });

        // Helper function to scroll chat to bottom
        const scrollToBottom = () => {
            if (chatArea.value) {
                chatArea.value.scrollTop = chatArea.value.scrollHeight;
            }
        };

        return {
            companyDetails,
            yTunnus,
            message,
            chatHistory,
            loading,
            error,
            chatArea,
            activeTab,
            loadCompanyInfo,
            sendMessage,
            renderMarkdown,
            formatTime,
            scrollToBottom
        };
    },
};
</script>

<style>
/* Import the fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

/* Base styles */
body {
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
}

/* Smooth scrolling */
.scroll-smooth {
    scroll-behavior: smooth;
}

/* Markdown styles */
.prose h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #0f766e;
}

.prose h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    color: #0f766e;
}

.prose h3 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

.prose p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.prose ul,
.prose ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
}

.prose ul {
    list-style-type: disc;
}

.prose ol {
    list-style-type: decimal;
}

.prose li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.prose code {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.875rem;
    background-color: #f1f5f9;
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
}

.prose pre {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.875rem;
    background-color: #1e293b;
    color: #f1f5f9;
    padding: 0.75rem;
    border-radius: 0.375rem;
    overflow-x: auto;
    margin: 0.75rem 0;
}

.prose blockquote {
    border-left: 3px solid #0d9488;
    padding-left: 0.75rem;
    color: #475569;
    font-style: italic;
    margin: 0.75rem 0;
}

.prose strong {
    font-weight: 600;
    color: #0f766e;
}

.prose em {
    font-style: italic;
}

.prose a {
    color: #0891b2;
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: color 0.2s;
}

.prose a:hover {
    color: #0e7490;
}

.prose hr {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid #e2e8f0;
}

.prose table {
    width: 100%;
    border-collapse: collapse;
    margin: 0.75rem 0;
}

.prose th {
    background-color: #f1f5f9;
    font-weight: 600;
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #cbd5e1;
}

.prose td {
    padding: 0.5rem;
    border: 1px solid #cbd5e1;
}

/* Message animation */
@keyframes messageIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-area>div {
    animation: messageIn 0.3s ease-out;
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #0d9488;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #0f766e;
}

/* Loading animation */
@keyframes typingBounce {

    0%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-5px);
    }
}

.typing-indicator .dot {
    animation: typingBounce 1.4s infinite ease-in-out both;
}

.typing-indicator .dot:nth-child(1) {
    animation-delay: -0.32s;
}

.typing-indicator .dot:nth-child(2) {
    animation-delay: -0.16s;
}
</style>